.home__new__card__container {
	display: flex;
	flex-direction: column;
	gap: 20px;
	width: 413px;
	cursor: pointer;
	text-decoration: none;

	@media screen and (max-width: 500px) {
		width: 290px;
	}

	.new__card__img {
		position: relative;
		width: 100%;
		height: 300px;
		border-radius: 10px;

		@media screen and (max-width: 500px) {
			height: 175px;
		}

		.new__card__heart {
			position: absolute;
		}

		.new__card__signature {
			width: 100%;
			position: relative;

			.top__right {
				.icon__signature {
					position: absolute;
					top: 18px;
					left: 18px;
					z-index: 2;

					@media screen and (max-width: 768px) {
						top: 12px;
						left: 12px;
					}

					svg {
						width: 180px;
						height: 30px;

						@media screen and (max-width: 768px) {
							height: 20px;
							width: 120px;
						}
					}
				}
				.icon__newhome {
					position: absolute;
					top: 14px;
					right: 12px;
					z-index: 2;
					width: max-content;

					@media screen and (max-width: 768px) {
						top: 10px;
						right: 8px;
					}

					svg {
						@media screen and (max-width: 768px) {
							height: 30px;
							width: max-content;
						}
					}
				}
			}
			.new__card__avail__detail {
				position: absolute;
				display: flex;
				flex-direction: column;
				gap: 10px;
				z-index: 2;
				padding-left: 20px;
				bottom: 5px;

				.icon-avail {
					display: flex;
					align-items: center;
					justify-content: center;
					width: max-content;
					border-radius: 35px;
					background-color: #e7f9e7;
					color: #11bf0e;
					padding: 5px 12px;
					font-size: 10px;
					font-weight: 600;
					line-height: 15px;
					border: 1px solid rgba(17, 191, 14, 0.2);

					@media screen and (max-width: 500px) {
						font-size: 8px;
						font-weight: 400;
						line-height: 12px;
						padding: 3px 8px;
					}
				}
				.not-avail {
					border-radius: 35px;
					background-color: #fff4f4;
					color: #fa4b4b;
					padding: 5px 12px;
					font-size: 10px;
					font-weight: 600;
					line-height: 15px;
					border: 1px solid rgba(250, 75, 75, 0.2);

					@media screen and (max-width: 500px) {
						font-size: 8px;
						font-weight: 400;
						line-height: 12px;
						padding: 3px 8px;
					}
				}

				.detail__container {
					display: flex;
					padding: 5px 0;

					span {
						color: #fff;
						font-size: 14px;
						font-weight: 400;
						padding: 2px 10px;
						height: min-content;

						@media screen and (max-width: 500px) {
							font-size: 10px;
							font-weight: 400;
							line-height: 15px;
						}

						&:nth-child(even) {
							border-inline: 1px solid #fff;
						}
					}
				}
			}
		}

		.new__card__img__box {
			position: relative;
			width: 100%;
			border-radius: 10px;
			height: 300px;

			@media screen and (max-width: 500px) {
				height: 175px;
			}

			img {
				width: 100%;
				height: 300px;
				object-fit: cover;
				border-radius: 10px;

				@media screen and (max-width: 500px) {
					height: 175px;
				}
			}
		}
	}

	.bottom__container {
		display: flex;
		flex-direction: column;
		gap: 16px;

		.title__container {
			display: flex;
			align-items: flex-start;
			justify-content: space-between;

			.title {
				display: flex;
				flex-direction: column;
				gap: 4px;

				h2 {
					color: #333333;
					font-size: 20px;
					font-weight: 700;
					line-height: 30px;

					@media screen and (max-width: 500px) {
						font-size: 16px;
						font-weight: 700;
						line-height: 24px;
					}
				}

				p {
					color: #555555;
					font-size: 16px;
					font-weight: 400;
					line-height: 24px;

					@media screen and (max-width: 500px) {
						font-size: 12px;
						font-weight: 400;
						line-height: 18px;
					}
				}
			}

			.rating {
				display: flex;
				align-items: center;
				gap: 3px;
				span {
					display: flex;
					align-items: center;
					color: #555555;
					@media screen and (max-width: 800px) {
						font-size: 14px;
						line-height: 21px;
						color: #555;
					}
				}

				p {
					color: #333333;
					font-size: 20px;
					font-weight: 700;
					line-height: 30px;

					@media screen and (max-width: 500px) {
						font-size: 14px;
						font-weight: 700;
						line-height: 21px;
					}
				}
			}
		}

		.price__container {
			display: flex;
			flex-direction: column;
			gap: 4px;

			.price {
				display: flex;
				gap: 20px;

				.price__per {
					display: flex;
					align-items: center;
					gap: 2px;

					h4 {
						color: #333333;
						font-size: 20px;
						font-weight: 600;
						line-height: 30px;

						@media screen and (max-width: 500px) {
							font-size: 14px;
							font-weight: 600;
							line-height: 21px;
						}
					}

					p {
						color: #666666;
						font-size: 14px;
						font-weight: 400;
						line-height: 21px;

						@media screen and (max-width: 500px) {
							font-size: 10px;
							font-weight: 400;
							line-height: 15px;
						}
					}
				}
			}
			.discount__price {
				position: relative;
				color: #666666;
				display: flex;
				font-size: 14px;
				font-weight: 400;
				line-height: 21px;
				text-align: left;

				@media screen and (max-width: 500px) {
					font-size: 10px;
					font-weight: 400;
					line-height: 15px;
				}

				hr {
					position: absolute;
					width: 60px;
					top: 50%;
					border: none;
					background-color: #666666;
					border-top: 1px solid #666666;
				}
			}
		}
	}

	.slick-slider {
		&:hover {
			.slick-arrow {
				opacity: 1;
			}
		}

		&:after {
			content: "";
			position: absolute;
			background: linear-gradient(180deg, transparent 0, transparent 80%, #000);
			opacity: 0.8;
			inset: 0 0 5px 0;
			border-radius: 10px;
			pointer-events: none;
		}
	}

	.button__bar {
		position: absolute;
		display: none !important;
		// display: flex;
		vertical-align: middle;
		margin: auto;
		padding: 0;
		border-radius: 40px;
		width: 40%;
		text-align: center;
		z-index: 20;
	}
}

.slick-arrow {
	opacity: 0;
	transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-webkit-transition: all 0.3s ease-in-out;
	z-index: 100;

	&:hover {
		opacity: 1;
	}
}

.slick-prev {
	opacity: 0;
	left: 6px;
	z-index: 10;
	height: 48px;
	width: 48px;

	@media screen and (max-width: 767px) {
		opacity: 0 !important;
	}
}

.slick-next {
	opacity: 0;
	right: 8px;
	height: 48px;
	width: 48px;
	z-index: 10;

	@media screen and (max-width: 767px) {
		opacity: 0 !important;
	}
}

.button__bar li {
	list-style: none;
	cursor: pointer;
	display: inline-block;
	margin: 0 3px;
	padding: 0;
}

.button__bar li button {
	border: none;
	background: var(--bg-white);
	opacity: 0.6;
	color: transparent;
	cursor: pointer;
	display: block;
	height: 7px;
	width: 7px;
	border-radius: 7.5px;
}

.button__bar li.slick-active button {
	background-color: var(--bg-white);
	opacity: 1;
	transform: scale(1.2);
}

.button__bar li button:hover,
.button__bar li button:focus {
	background: var(--bg-white);
	outline: 0;
}

.slick-slide img {
	display: block;
	margin: auto;
	z-index: -100;

	@media screen and (max-width: 500px) {
		min-height: 175px;
	}
}

[data-title] {
	font-size: 30px;
	/*optional styling*/
	position: relative;
}

[data-title]:hover::before {
	content: attr(data-title);
	position: absolute;
	bottom: -26px;
	display: inline-block;
	padding: 3px 6px;
	border-radius: 2px;
	background: #000;
	color: #fff;
	font-size: 12px;
	font-family: sans-serif;
	white-space: nowrap;
}

[data-title]:hover::after {
	content: "";
	position: absolute;
	bottom: -10px;
	left: 8px;
	display: inline-block;
	color: #fff;
	border: 8px solid transparent;
	border-bottom: 8px solid #000;
}
